import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import {
    Advertisement,
    AdvertisementFilterType,
    AdvertisementPlacement,
    AdvertisementType,
    AdvertisementViewModel
} from '../../models/advertisement.model';
import { Campaign } from '../../models/campaign.model';
import { Giveaway } from '../../models/giveaway.model';
import { Redemption } from '../../models/redemption.model';
import { UserRoles } from '../../models/user-role-map.model';
import { UserSubscription } from '../../models/user-subscription.model';
import { ReferralState } from '../reducers/referral.reducer';
import { GlobalState } from '../store';
import { selectCurrentSubscription } from './payment.selectors';
import { selectAllGiveawayRedemptions } from './persist.selectors';
import * as routeSelectors from './route.selectors';
import { selectUserRoles } from './users.selectors';

const selectReferral = createFeatureSelector<MemoizedSelector<GlobalState, any>>(
    'referral'
).projector((state: GlobalState) => state.referral);

export const selectCampaignId = createSelector(
    selectReferral,
    (state: ReferralState) => state.campaignId
);
export const selectPromoCodeData = createSelector(
    selectReferral,
    selectCampaignId,
    (state: ReferralState, campaignId) => {
        return {
            ...state.promoCodeData,
            campaignId
        };
    }
);
export const selectCampaignData = createSelector(
    selectReferral,
    selectCampaignId,
    (state: ReferralState, campaignId) => state.campaignData
);
export const selectPromoCodes = createSelector(
    selectReferral,
    (state: ReferralState) => state.promoCodes
);
export const selectCampaigns = createSelector(
    selectReferral,
    (state: ReferralState) => state.campaigns
);
export const selectAffiliatePromoCodes = createSelector(
    selectReferral,
    (state: ReferralState) => state.affiliatePromoCodes
);
export const selectAffiliateEvents = createSelector(
    selectReferral,
    (state: ReferralState) => state.affiliateEvents
);
export const selectAffiliatePayments = createSelector(
    selectReferral,
    (state: ReferralState) => state.affiliatePayments
);
export const selectEarningsData = createSelector(
    selectReferral,
    (state: ReferralState) => state.earningsData
);
export const selectEnabledBannerCampaigns = createSelector(
    selectReferral,
    routeSelectors.selectUrl,
    (state: ReferralState, url: string) => {
        const campaigns = state.enabledBannerCampaigns;
        return campaigns
            .map(c => {
                const regex = new RegExp(`(\/${c.game})`);
                return { ...c, gameRouteMatch: regex.test(url) };
            })
            .filter(campaign => {
                return !!(
                    campaign?.bannerSettings?.enabled &&
                    campaign.bannerSettings.endDate &&
                    campaign.bannerSettings.startDate &&
                    new Date() < new Date(campaign.bannerSettings.endDate) &&
                    new Date() > new Date(campaign.bannerSettings.startDate)
                );
            });
    }
);
export const selectEnabledModalCampaigns = createSelector(
    selectReferral,
    routeSelectors.selectUrl,
    (state: ReferralState, url: string) => {
        const campaigns = state.enabledModalCampaigns;
        return campaigns
            .map(c => {
                const regex = new RegExp(`(\/${c.game})`);
                return { ...c, gameRouteMatch: regex.test(url) };
            })
            .filter(campaign => {
                return campaign?.modalSettings?.enabled;
            });
    }
);
export const selectEnabledBannerCampaign = createSelector(
    selectEnabledBannerCampaigns,
    (campaigns: Campaign[]) => {
        let gameCampaign = campaigns.find(
            c => c.gameRouteMatch === true && c.overridePromoCodeGame === true
        );

        if (!gameCampaign) {
            gameCampaign = campaigns.find(c => !c.overridePromoCodeGame);
        }
        return gameCampaign || null;
    }
);
export const selectEnabledModalCampaign = createSelector(
    selectEnabledModalCampaigns,
    (campaigns: Campaign[]) => {
        let gameCampaign = campaigns.find(
            c => c.gameRouteMatch === true && c.overridePromoCodeGame === true
        );

        if (!gameCampaign) {
            gameCampaign = campaigns.find(c => !c.overridePromoCodeGame);
        }
        return gameCampaign || null;
    }
);
export const selectActiveAdCampaignsAdvertisements = createSelector(
    selectReferral,
    (state: ReferralState) => state.activeAdCampaignsAdvertisements
);
export const selectActiveAdCampaignsAdvertisementsLoadingComplete = createSelector(
    selectReferral,
    (state: ReferralState) => state.activeAdCampaignsAdvertisementsLoadingComplete
);
export const selectFirstVisitTracked = createSelector(
    selectReferral,
    (state: ReferralState) => state.firstVisitTracked
);
export const selectPreviousAffiliateId = createSelector(
    selectReferral,
    (state: ReferralState) => state.previousAffiliateId
);
export const selectEnabledGiveaways = createSelector(selectReferral, (state: ReferralState) => {
    return state.enabledGiveaways;
});
export const selectRedeemableGiveaways = createSelector(
    selectEnabledGiveaways,
    selectAllGiveawayRedemptions,
    selectUserRoles,
    selectCurrentSubscription,
    (
        giveaways: Giveaway[],
        redemptions: Redemption[],
        roles: UserRoles,
        subscription: UserSubscription
    ) => {
        // Make sure giveaway settings include the user's role or their subscription status.
        // If one of those matches (OR) the giveaway is considered redeemable by this point.
        giveaways = giveaways.filter(giv => {
            const targetRoles = giv.targetedUserRoles || [];
            const targetSubStatuses = giv.targetedSubscriptionStatuses;
            const roleCheck =
                targetRoles.length > 0
                    ? roles.allRoles.some(role => targetRoles?.includes(role))
                    : true;
            const subCheck =
                subscription != null
                    ? targetSubStatuses.length > 0 &&
                      targetSubStatuses?.includes(subscription.status)
                    : true;

            return roleCheck || subCheck;
        });

        // Finally, make sure the giveaway is not already redeemed fully.
        // Fully means that the item limit of the giveaway was reached and there are no more items to redeem.
        return giveaways.filter(giv => {
            if (!giv.itemLimited) {
                return !redemptions.some(
                    red =>
                        red.giveaway._id === giv._id && red.items.length >= giv.redeemableItemsLimit
                );
            } else {
                return giv.redeemableItems.some(item => {
                    return !redemptions.some(red => red.items.some(i => i.itemId == item.itemId));
                });
            }
        });
    }
);
export const selectRedeemableItemsCountByType = createSelector(
    selectRedeemableGiveaways,
    selectAllGiveawayRedemptions,
    (giveaways: Giveaway[], redemptions: Redemption[]) => {
        let redeemableWithGiveaway = {};

        const redeemableItemsByType = giveaways
            .filter(g => !g.itemLimited)
            .reduce((acc, giveaway) => {
                if (!acc[giveaway.type]) {
                    acc[giveaway.type] = giveaway.redeemableItemsLimit;
                    redeemableWithGiveaway[giveaway.type] = [];
                } else {
                    acc[giveaway.type] += giveaway.redeemableItemsLimit;
                }
                const redeemedItemsForGiveaway =
                    redemptions.find(red => {
                        return red.giveaway._id === giveaway._id;
                    })?.items.length || 0;
                acc[giveaway.type] -= redeemedItemsForGiveaway;

                if (redeemedItemsForGiveaway < giveaway.redeemableItemsLimit) {
                    redeemableWithGiveaway[giveaway.type].push(giveaway._id);
                }

                return acc;
            }, {});
        const redeemableItems = giveaways
            .filter(g => g.itemLimited)
            .reduce((acc, giveaway) => {
                giveaway.redeemableItems.forEach(item => {
                    if (!redemptions.some(red => red.items.some(i => i.itemId == item.itemId))) {
                        acc[item.itemId] = giveaway._id;
                    }
                });
                return acc;
            }, {});

        return {
            redemptions: redemptions,
            redeemableItemsByTypeGiveaways: redeemableWithGiveaway,
            redeemableItemsByType: redeemableItemsByType,
            redeemableItems
        };
    }
);
export const selectRedeemedItemIds = createSelector(
    selectAllGiveawayRedemptions,
    (redemptions: Redemption[]): string[] => {
        return Array.from(
            redemptions.reduce((items, redemption) => {
                redemption.items.forEach(i => items.add(i.itemId));
                return items;
            }, new Set<string>())
        );
    }
);
export const selectAdvertisementsByPlacementAndFilter = (
    placements: AdvertisementPlacement | AdvertisementPlacement[],
    filter: string,
    filterType: AdvertisementFilterType
) => {
    const allPlacements: AdvertisementPlacement[] = Array.isArray(placements)
        ? placements
        : [placements];

    return createSelector(selectActiveAdCampaignsAdvertisements, (ads: Advertisement[]): {
        [key in AdvertisementPlacement]: AdvertisementViewModel;
    } => {
        const mappedAds: AdvertisementViewModel[] = ads.map(ad => {
            return {
                ...ad,
                mobileItem: ad.items.find(i => i.type === AdvertisementType.MOBILE),
                desktopItem: ad.items.find(i => i.type === AdvertisementType.DESKTOP)
            };
        }) as AdvertisementViewModel[];
        // if there is more than one ad for a specific placement, randomly pick one
        const groupedBackfillAds = mappedAds
            .filter(
                ad =>
                    (allPlacements.includes(ad.placement) && !ad.filter) ||
                    (ad.filter === filter && ad.filterType === filterType)
            )
            .reduce((acc, ad) => {
                if (acc[ad.placement]) {
                    acc[ad.placement].push(ad);
                } else {
                    acc[ad.placement] = [ad];
                }

                return acc;
            }, {} as { [key in AdvertisementPlacement]: AdvertisementViewModel[] });

        const groupedAdsMatchingFilter = mappedAds
            .filter(
                ad =>
                    allPlacements.includes(ad.placement) &&
                    ad.filter === filter &&
                    ad.filterType === filterType
            )
            .reduce((acc, ad) => {
                if (acc[ad.placement]) {
                    acc[ad.placement].push(ad);
                } else {
                    acc[ad.placement] = [ad];
                }

                return acc;
            }, {} as { [key in AdvertisementPlacement]: AdvertisementViewModel[] });

        return allPlacements.reduce((acc, placement) => {
            let group = groupedAdsMatchingFilter[placement]
                ? groupedAdsMatchingFilter
                : groupedBackfillAds;

            if (!group[placement]) {
                return acc;
            }

            if (group[placement].length > 1) {
                const randomIndex = Math.floor(Math.random() * group[placement].length);
                acc[placement] = group[placement][randomIndex];
            } else {
                acc[placement] = group[placement][0];
            }

            return acc;
        }, {} as { [key in AdvertisementPlacement]: AdvertisementViewModel });
    });
};

