import { createAction, props } from '@ngrx/store';
import { Article } from '../../models/article.model';
import { Course } from '../../models/course.model';
import { APIError } from '../../models/error.model';
import { Player } from '../../models/player.model';
import { VideoGuide } from '../../models/video-guide.model';

export enum SearchActionsTypes {
    LoadAutocompleteResults = '[Dashboard Search] Load Autocomplete Results',
    LoadAutocompleteResultsSuccess = '[Content API] Load Autocomplete Results Success',
    LoadAutocompleteResultsFailure = '[Content API] Load Autocomplete Results Failure',
    AutocompleteResultsReset = '[Dashboard Search] Autocomplete Results Reset',

    LoadSuggestedContent = '[Dashboard Search] Load Suggested Content',
    LoadSuggestedContentSuccess = '[Content API] Load Suggested Content Success',
    LoadSuggestedContentFailure = '[Content API] Load Suggested Content Failure',
    SuggestedContentReset = '[Dashboard Search] Suggested Content Reset',

    AutocompleteTermAdd = '[Dashboard Search] Autocomplete Term Add',
    AutocompleteTermRemove = '[Dashboard Search] Autocomplete Term Remove'
}

export const loadAutocompleteResults = createAction(
    SearchActionsTypes.LoadAutocompleteResults,
    props<{
        payload: {
            term: string;
            entitiesSuggestCount?: number;
        };
    }>()
);
export const loadAutocompleteResultsSuccess = createAction(
    SearchActionsTypes.LoadAutocompleteResultsSuccess,
    props<{ payload: { text: string; context: string }[] }>()
);
export const loadAutocompleteResultsFailure = createAction(
    SearchActionsTypes.LoadAutocompleteResultsFailure,
    props<{ payload: APIError }>()
);
export const autocompleteResultsReset = createAction(SearchActionsTypes.AutocompleteResultsReset);

export const loadSuggestedContent = createAction(SearchActionsTypes.LoadSuggestedContent);
export const loadSuggestedContentSuccess = createAction(
    SearchActionsTypes.LoadSuggestedContentSuccess,
    props<{
        payload: {
            videoGuides: VideoGuide[];
            players: Player[];
            courses: Course[];
            articles: Article[];
        };
    }>()
);
export const loadSuggestedContentFailure = createAction(
    SearchActionsTypes.LoadSuggestedContentFailure,
    props<{ payload: APIError }>()
);
export const suggestedContentReset = createAction(SearchActionsTypes.SuggestedContentReset);

export const autocompleteTermAdd = createAction(
    SearchActionsTypes.AutocompleteTermAdd,
    props<{
        payload: {
            term: string;
        };
    }>()
);
export const autocompleteTermRemove = createAction(
    SearchActionsTypes.AutocompleteTermRemove,
    props<{
        payload: {
            term: string;
        };
    }>()
);

