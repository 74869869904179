import { createAction, props } from '@ngrx/store';
import { Game } from '../../core/constants/game.enum';

export enum PersistActionsTypes {
    SetGame = '[App Global] Set Game',
    SetCurrentGame = '[App Global] Set Current Game',
    SetAuthToken = '[App Global] Set Auth Token',
    SetRefreshToken = '[App Global] Set Refresh Token',
    DismissCookiesBanner = '[Cookies Banner] Dismiss Cookies Banner',
    DismissAnnouncementOverlay = '[Cookies Banner] Announcement Overlay Dismiss',
    DismissGiveawayOverlay = '[Giveaway Modal] Giveaway Overlay Dismiss',
    DismissPushNotificationsBanner = '[Push Notifications Banner] Push Notifications Banner Dismiss',
    CollapsePromoBanner = '[Promo Banner] Promo Banner Collapse',
    ExpandPromoBanner = '[Promo Banner] Promo Banner Expand',
    SetPlayerSettings = '[Video Player] Set Player Settings',
    ResetDashboardContent = '[Persist Effects] Reset Dashboard Content',
}

export const setGame = createAction(PersistActionsTypes.SetGame, props<{ payload: Game }>());
export const setCurrentGame = createAction(
    PersistActionsTypes.SetCurrentGame,
    props<{ payload: Game | null }>()
);
export const setAuthToken = createAction(
    PersistActionsTypes.SetAuthToken,
    props<{ payload: string | null }>()
);
export const setRefreshToken = createAction(
    PersistActionsTypes.SetRefreshToken,
    props<{ payload: string | null }>()
);
export const dismissCookiesBanner = createAction(PersistActionsTypes.DismissCookiesBanner);
export const dismissAnnouncementOverlay = createAction(
    PersistActionsTypes.DismissAnnouncementOverlay,
    props<{ payload: string }>()
);
export const dismissGiveawayOverlay = createAction(
    PersistActionsTypes.DismissGiveawayOverlay,
    props<{ payload: string }>()
);
export const dismissPushNotificationsBanner = createAction(
    PersistActionsTypes.DismissPushNotificationsBanner
);
export const collapsePromoBanner = createAction(PersistActionsTypes.CollapsePromoBanner);
export const expandPromoBanner = createAction(PersistActionsTypes.ExpandPromoBanner);
export const setPlayerSettings = createAction(
    PersistActionsTypes.SetPlayerSettings,
    props<{
        payload: {
            speed?: number;
            quality?: number;
        };
    }>()
);
export const resetDashboardContent = createAction(PersistActionsTypes.ResetDashboardContent);

