export enum Game {
    DOTA = 'dota',
    OVERWATCH2 = 'overwatch2',
    APEX = 'apex',
    LEAGUE = 'league',
    VALORANT = 'valorant',
    HALO = 'halo',
    FORTNITE = 'fortnite'
}

export const GameDisplayName = {
    [Game.DOTA]: 'Dota 2',
    [Game.OVERWATCH2]: 'Overwatch 2',
    [Game.APEX]: 'Apex Legends',
    [Game.LEAGUE]: 'League of Legends',
    [Game.VALORANT]: 'Valorant',
    [Game.HALO]: 'Halo Infinite',
    [Game.FORTNITE]: 'Fortnite',
    diablo4: 'Diablo 4',
    cod: 'Call of Duty',
    starfield: 'Starfield',
    cyberpunk: 'Cyberpunk 2077',
    assassinscreedmirage: `Assassin's Creed Mirage`,
    liesofp: 'Lies of P',
    partyanimals: 'Party Animals',
    baldursgate3: `Baldur's Gate 3`,
    counterstrike2: `Counter-Strike 2`,
    eldenring: `Elden Ring`,
    lordsofthefallen: 'Lords of the Fallen',
    spiderman2: `Marvel's Spider-Man 2`,
    payday3: `Payday 3`,
    alanwake2: `Alan Wake 2`,
    worldofwarcraft: 'World of Warcraft',
    teamfighttactics: `Teamfight Tactics`,
    blackdesertonline: `Black Desert Online`
};

export const GameColor = {
    [Game.DOTA]: '#FF4136', // red
    [Game.OVERWATCH2]: '#FF851B', // orange
    [Game.APEX]: '#7FDBFF', // light blue
    [Game.LEAGUE]: '#FFDC00', // yellow
    [Game.VALORANT]: '#B10DC9', // purple
    [Game.HALO]: '#2ECC40', // green
    [Game.FORTNITE]: '#FF4136', // red
    diablo4: '#FF851B', // orange
    cod: '#7FDBFF', // light blue
    starfield: '#FFDC00', // yellow
    cyberpunk: '#B10DC9', // purple
    assassinscreedmirage: '#FFD700', // gold
    liesofp: '#2ECC40', // green
    partyanimals: '#FF4136', // red
    baldursgate3: '#FF851B', // orange
    counterstrike2: '#7FDBFF', // light blue
    eldenring: '#FFDC00', // yellow
    spiderman2: '#FF69B4', // pink
    lordsofthefallen: '#8B008B', // dark magenta
    payday3: '#FFA500', // orange/yellow
    alanwake2: '#7FDBFF', // light blue
    worldofwarcraft: '#FFDC00', // yellow
    teamfighttactics: '#B10DC9', // purple
    blackdesertonline: '#FF4136' // red
};

export const GameDeveloperName = {
    [Game.DOTA]: 'Valve',
    [Game.OVERWATCH2]: 'Blizzard Entertainment',
    [Game.APEX]: 'Respawn Entertainment',
    [Game.LEAGUE]: 'Riot Games',
    [Game.VALORANT]: 'Riot Games',
    [Game.HALO]: '343 Industries',
    [Game.FORTNITE]: 'Epic Games',
    diablo4: 'Blizzard Entertainment',
    cod: 'Activision',
    starfield: 'Bethesda Softworks',
    cyberpunk: 'CD Projekt Red',
    assassinscreedmirage: 'Ubisoft',
    liesofp: 'Morowitz Games, Neon Giant',
    partyanimals: 'Recreate Games',
    baldursgate3: `Larian Studios`,
    counterstrike2: `Valve`,
    eldenring: `FromSoftware`,
    lordsofthefallen: 'Hexworks',
    spiderman2: `Insomniac Games`,
    payday3: `Starbreeze Studios, Overkill Software`,
    alanwake2: `Remedy Entertainment`,
    worldofwarcraft: 'Blizzard Entertainment',
    teamfighttactics: `Riot Games`,
    blackdesertonline: `Pearl Abyss`
};
export const Games = Object.values(Game) as Game[];
export const ArticlesGames = [
    ...Object.values(Game),
    'diablo4',
    'cod',
    'starfield',
    'cyberpunk',
    'assassinscreedmirage',
    'liesofp',
    'partyanimals',
    'baldursgate3',
    'counterstrike2',
    'eldenring',
    'lordsofthefallen',
    'spiderman2',
    'payday3',
    'alanwake2',
    'worldofwarcraft',
    'teamfighttactics',
    'blackdesertonline'
] as Game[];

export const GameMetadata = {
    [Game.DOTA]: {
        displayName: GameDisplayName[Game.DOTA],
        logoPath: '/assets/images/dota/dota2logo_small.webp'
    },
    [Game.LEAGUE]: {
        displayName: GameDisplayName[Game.LEAGUE],
        logoPath: '/assets/images/league/leaguelogo_small.webp'
    },
    ['overwatch']: {
        displayName: GameDisplayName[Game.OVERWATCH2],
        logoPath: '/assets/images/overwatch/overwatch_logo_small.webp'
    },
    [Game.OVERWATCH2]: {
        displayName: GameDisplayName[Game.OVERWATCH2],
        logoPath: '/assets/images/overwatch/overwatch_logo_small.webp'
    },
    [Game.VALORANT]: {
        displayName: GameDisplayName[Game.VALORANT],
        logoPath: '/assets/images/valorant/valorantlogo_small.webp'
    },
    [Game.APEX]: {
        displayName: GameDisplayName[Game.APEX],
        logoPath: '/assets/images/apex/apexlogo_small.webp'
    },
    [Game.HALO]: {
        displayName: GameDisplayName[Game.HALO],
        logoPath: '/assets/images/halo/haloinfinitelogo_small.webp'
    },
    [Game.FORTNITE]: {
        displayName: GameDisplayName[Game.FORTNITE],
        logoPath: '/assets/images/fortnite/fortnite_logo_small.webp'
    }
};

export const GameArticleCategorySlugMap = {
    [Game.DOTA]: 'dota-2',
    [Game.LEAGUE]: 'league-of-legends',
    ['overwatch']: 'overwatch-2',
    [Game.OVERWATCH2]: 'overwatch-2',
    [Game.VALORANT]: 'valorant',
    [Game.APEX]: 'apex-legends',
    [Game.HALO]: 'halo-infinite',
    [Game.FORTNITE]: 'fortnite'
};

// reverse the above map
export const GameSlugArticleCategoryMap = Object.keys(GameArticleCategorySlugMap).reduce(
    (acc, game) => ({
        ...acc,
        [GameArticleCategorySlugMap[game]]: game
    }),
    {}
) as { [key: string]: Game };

