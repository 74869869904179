import { createAction, props } from '@ngrx/store';
import { AdEvent } from '../../models/ad-event.model';
import { Advertisement } from '../../models/advertisement.model';
import { AffiliateEvent } from '../../models/affiliate-event.model';
import { Campaign } from '../../models/campaign.model';
import { APIError } from '../../models/error.model';
import { Giveaway } from '../../models/giveaway.model';
import { PaymentType } from '../../models/payment.model';
import { PromoCode } from '../../models/promo-code.model';
import { Redemption } from '../../models/redemption.model';

export enum ReferralActionsTypes {
    ValidateVisitorPromoCode = '[App Global] Validate Visitor Promo Code',
    ValidateBannerPromoCode = '[Promo Banner] Validate Banner Promo Code',
    ValidateCheckoutPromoCode = '[Checkout Page] Validate Checkout Promo Code',
    ValidateAnnouncementPromoCode = '[Checkout Page] Validate Checkout Promo Code',

    ValidatePromoCodeSuccess = '[Referral API] Validate Promo Code Success',
    ValidatePromoCodeFailure = '[Referral Effects] Validate Promo Code Failure',

    ValidateVisitorCampaign = '[App Global] Validate Visitor Campaign',

    ValidateCampaignSuccess = '[App Global] Validate Campaign Success',
    ValidateCampaignFailure = '[App Global] Validate Campaign Failure',

    ResetPromoCodeData = '[Checkout Page] Reset Promo Code Data',
    ResetVisitorPromoCodeData = '[Referral Effects] Reset Visitor Promo Code Data',

    TrackVisitEvent = '[App Global] Track Visit Event',
    TrackVisitEventSuccess = '[Referrals API] Track Visit Event Success',
    TrackVisitEventFailure = '[Referrals API] Track Visit Event Failure',

    TrackProductSubscribeEvent = '[App Global] Track Product Subscribe Event',
    TrackProductSubscribeEventSuccess = '[Referrals API] Track Product Subscribe Event Success',
    TrackProductSubscribeEventFailure = '[Referrals API] Track Product Subscribe Event Failure',

    LoadAffiliateEarningsData = '[Partner Page] Load Affiliate Earnings Data',
    LoadAffiliateEarningsDataSuccess = '[Referrals API] Load Affiliate Earnings Data Success',
    LoadAffiliateEarningsDataFailure = '[Referrals API] Load Affiliate Earnings Data Failure',

    LoadAffiliateEvents = '[Partner Page] Load Affiliate Events',
    LoadAffiliateEventsSuccess = '[Referrals API] Load Affiliate Events Success',
    LoadAffiliateEventsFailure = '[Referrals API] Load Affiliate Events Failure',

    LoadAffiliatePromoCodes = '[Partner Page] Load Affiliate Promo Codes',
    LoadAffiliatePromoCodesSuccess = '[Referrals API] Load Affiliate Promo Codes Success',
    LoadAffiliatePromoCodesFailure = '[Referrals API] Load Affiliate Promo Codes Failure',

    LoadAffiliatePayments = '[Partner Page] Load Affiliate Payments',
    LoadAffiliatePaymentsSuccess = '[Referrals API] Load Affiliate Payments Success',
    LoadAffiliatePaymentsFailure = '[Referrals API] Load Affiliate Payments Failure',
    ResetAffiliatePayments = '[Partner Page] Reset Affiliate Payments',

    LoadAffiliateCampaigns = '[Partner Page] Load Affiliate Campaigns',
    LoadAffiliateCampaignsSuccess = '[Referrals API] Load Affiliate Campaigns Success',
    LoadAffiliateCampaignsFailure = '[Referrals API] Load Affiliate Campaigns Failure',

    LoadEnabledBannerCampaigns = '[App Global] Load Enabled Banner Campaigns',
    LoadEnabledBannerCampaignsSuccess = '[Referrals API] Load Enabled Banner Campaigns Success',
    LoadEnabledBannerCampaignsFailure = '[Referrals API] Load Enabled Banner Campaigns Failure',

    LoadEnabledModalCampaigns = '[App Global] Load Enabled Modal Campaigns',
    LoadEnabledModalCampaignsSuccess = '[Referrals API] Load Enabled Modal Campaigns Success',
    LoadEnabledModalCampaignsFailure = '[Referrals API] Load Enabled Modal Campaigns Failure',

    SetCampaignId = '[App Global] Set Campaign Id',
    SetFirstVisit = '[App Global] Set First Visit',
    SetPreviousAffiliateId = '[App Global] Set Previous Affiliate Id',

    LoadEnabledGiveaways = '[App Global] Load Enabled Giveaways',
    LoadEnabledGiveawaysSuccess = '[Referrals API] Load Enabled Giveaways Success',
    LoadEnabledGiveawaysFailure = '[Referrals API] Load Enabled Giveaways Failure',

    LoadGiveawayRedemptions = '[App Global] Load Giveaway Redemptions',
    LoadGiveawayRedemptionsSuccess = '[Referrals API] Load Giveaway Redemptions Success',
    LoadGiveawayRedemptionsFailure = '[Referrals API] Load Giveaway Redemptions Failure',

    RedeemGiveaway = '[App Global] Redeem Giveaway',
    RedeemGiveawayItem = '[Giveaway Item] Redeem Giveaway Item',
    RedeemGiveawaySuccess = '[Referrals API] Redeem Giveaway Success',
    RedeemGiveawayFailure = '[Referrals API] Redeem Giveaway Failure',

    LoadActiveAdCampaignsAdvertisements = '[App Global] Load Active Ad Campaigns Advertisements',
    LoadActiveAdCampaignsAdvertisementsSuccess = '[Referrals API] Load Active Ad Campaigns Advertisements Success',
    LoadActiveAdCampaignsAdvertisementsFailure = '[Referrals API] Load Active Ad Campaigns Advertisements Failure',

    SetLoadActiveAdCampaignsAdvertisementsComplete = '[Referrals API] Set Load Active Ad Campaigns Advertisements Complete',

    TrackAdImpressionEvent = '[Ad Service] Track Ad Impression Event',
    TrackAdImpressionEventSuccess = '[Referrals API] Track Ad Impression Event Success',
    TrackAdImpressionEventFailure = '[Referrals API] Track Ad Impression Event Failure',

    TrackAdClickEvent = '[Ad Service] Track Ad Click Event',
    TrackAdClickEventSuccess = '[Referrals API] Track Ad Click Event Success',
    TrackAdClickEventFailure = '[Referrals API] Track Ad Click Event Failure'
}

export const validateVisitorPromoCode = createAction(
    ReferralActionsTypes.ValidateVisitorPromoCode,
    props<{ payload: { promoCode: string } }>()
);
export const validateCheckoutPromoCode = createAction(
    ReferralActionsTypes.ValidateCheckoutPromoCode,
    props<{ payload: { promoCode: string | null } }>()
);
export const validateBannerPromoCode = createAction(
    ReferralActionsTypes.ValidateBannerPromoCode,
    props<{ payload: { promoCode: string | null | undefined } }>()
);
export const validateAnnouncementPromoCode = createAction(
    ReferralActionsTypes.ValidateAnnouncementPromoCode,
    props<{ payload: { promoCode: string | null | undefined } }>()
);
export const validatePromoCodeSuccess = createAction(
    ReferralActionsTypes.ValidatePromoCodeSuccess,
    props<{ payload: PromoCode }>()
);
export const validatePromoCodeFailure = createAction(
    ReferralActionsTypes.ValidatePromoCodeFailure,
    props<{ payload: APIError }>()
);
export const validateVisitorCampaign = createAction(
    ReferralActionsTypes.ValidateVisitorCampaign,
    props<{ payload: { campaignId: string } }>()
);
export const validateCampaignSuccess = createAction(
    ReferralActionsTypes.ValidateCampaignSuccess,
    props<{ payload: Campaign }>()
);
export const validateCampaignFailure = createAction(
    ReferralActionsTypes.ValidateCampaignFailure,
    props<{ payload: APIError }>()
);

export const resetPromoCodeData = createAction(ReferralActionsTypes.ResetPromoCodeData);
export const resetVisitorPromoCodeData = createAction(
    ReferralActionsTypes.ResetVisitorPromoCodeData
);

export const trackVisitEvent = createAction(
    ReferralActionsTypes.TrackVisitEvent,
    props<{ payload: { affiliateId: string; campaignId: string } }>()
);
export const trackVisitEventSuccess = createAction(
    ReferralActionsTypes.TrackVisitEventSuccess,
    props<{ payload: AffiliateEvent }>()
);
export const trackVisitEventFailure = createAction(
    ReferralActionsTypes.TrackVisitEventFailure,
    props<{ payload: APIError }>()
);

export const trackProductSubscribeEvent = createAction(
    ReferralActionsTypes.TrackProductSubscribeEvent,
    props<{
        payload: { affiliateId: string; campaignId: string | null; product: string; email: string };
    }>()
);
export const trackProductSubscribeEventSuccess = createAction(
    ReferralActionsTypes.TrackProductSubscribeEventSuccess,
    props<{ payload: AffiliateEvent }>()
);
export const trackProductSubscribeEventFailure = createAction(
    ReferralActionsTypes.TrackProductSubscribeEventFailure,
    props<{ payload: APIError }>()
);

export const loadAffiliateEarningsData = createAction(
    ReferralActionsTypes.LoadAffiliateEarningsData,
    props<{
        payload: {
            affiliateId: string;
            data?: {
                currency: string;
            };
        };
    }>()
);
export const loadAffiliateEarningsDataSuccess = createAction(
    ReferralActionsTypes.LoadAffiliateEarningsDataSuccess,
    props<{ payload: { [currency: string]: any[] } }>()
);
export const loadAffiliateEarningsDataFailure = createAction(
    ReferralActionsTypes.LoadAffiliateEarningsDataFailure,
    props<{ payload: APIError }>()
);

export const loadAffiliateEvents = createAction(
    ReferralActionsTypes.LoadAffiliateEvents,
    props<{
        payload: {
            startDate: string;
            endDate: string;
            affiliateId: string;
            campaignId: string | null;
            timeUnit: string;
            eventKind: string;
        };
    }>()
);
export const loadAffiliateEventsSuccess = createAction(
    ReferralActionsTypes.LoadAffiliateEventsSuccess,
    props<{ payload: any }>()
);
export const loadAffiliateEventsFailure = createAction(
    ReferralActionsTypes.LoadAffiliateEventsFailure,
    props<{ payload: APIError }>()
);

export const loadAffiliatePromoCodes = createAction(
    ReferralActionsTypes.LoadAffiliatePromoCodes,
    props<{ payload: { affiliate: string } }>()
);
export const loadAffiliatePromoCodesSuccess = createAction(
    ReferralActionsTypes.LoadAffiliatePromoCodesSuccess,
    props<{ payload: PromoCode[] }>()
);
export const loadAffiliatePromoCodesFailure = createAction(
    ReferralActionsTypes.LoadAffiliatePromoCodesFailure,
    props<{ payload: APIError }>()
);

export const loadAffiliatePayments = createAction(
    ReferralActionsTypes.LoadAffiliatePayments,
    props<{
        payload: {
            startDate: string;
            endDate: string;
            affiliateId: string;
            timeUnit: string;
            paymentType: PaymentType;
            currency: string;
        };
    }>()
);
export const loadAffiliatePaymentsSuccess = createAction(
    ReferralActionsTypes.LoadAffiliatePaymentsSuccess,
    props<{ payload: any }>()
);
export const loadAffiliatePaymentsFailure = createAction(
    ReferralActionsTypes.LoadAffiliatePaymentsFailure,
    props<{ payload: APIError }>()
);

export const resetAffiliatePayments = createAction(ReferralActionsTypes.ResetAffiliatePayments);

export const loadAffiliateCampaigns = createAction(
    ReferralActionsTypes.LoadAffiliateCampaigns,
    props<{ payload: { promoCodeId: string } }>()
);
export const loadAffiliateCampaignsSuccess = createAction(
    ReferralActionsTypes.LoadAffiliateCampaignsSuccess,
    props<{ payload: any }>()
);
export const loadAffiliateCampaignsFailure = createAction(
    ReferralActionsTypes.LoadAffiliateCampaignsFailure,
    props<{ payload: APIError }>()
);

export const loadEnabledBannerCampaigns = createAction(
    ReferralActionsTypes.LoadEnabledBannerCampaigns
);
export const loadEnabledBannerCampaignsSuccess = createAction(
    ReferralActionsTypes.LoadEnabledBannerCampaignsSuccess,
    props<{ payload: Campaign[] }>()
);
export const loadEnabledBannerCampaignsFailure = createAction(
    ReferralActionsTypes.LoadEnabledBannerCampaignsFailure,
    props<{ payload: APIError }>()
);
export const loadEnabledModalCampaigns = createAction(
    ReferralActionsTypes.LoadEnabledModalCampaigns
);
export const loadEnabledModalCampaignsSuccess = createAction(
    ReferralActionsTypes.LoadEnabledModalCampaignsSuccess,
    props<{ payload: Campaign[] }>()
);
export const loadEnabledModalCampaignsFailure = createAction(
    ReferralActionsTypes.LoadEnabledModalCampaignsFailure,
    props<{ payload: APIError }>()
);
export const setCampaignId = createAction(
    ReferralActionsTypes.SetCampaignId,
    props<{ payload: { campaignId: any } }>()
);
export const setFirstVisit = createAction(
    ReferralActionsTypes.SetFirstVisit,
    props<{ payload: { firstVisitTracked: boolean } }>()
);
export const setPreviousAffiliateId = createAction(
    ReferralActionsTypes.SetPreviousAffiliateId,
    props<{ payload: { previousAffiliateId: string } }>()
);
export const loadEnabledGiveaways = createAction(ReferralActionsTypes.LoadEnabledGiveaways);
export const loadEnabledGiveawaysSuccess = createAction(
    ReferralActionsTypes.LoadEnabledGiveawaysSuccess,
    props<{ payload: Giveaway[] }>()
);
export const loadEnabledGiveawaysFailure = createAction(
    ReferralActionsTypes.LoadEnabledGiveawaysFailure,
    props<{ payload: APIError }>()
);
export const redeemGiveaway = createAction(
    ReferralActionsTypes.RedeemGiveaway,
    props<{ payload: { giveawayId: string } }>()
);
export const redeemGiveawayItem = createAction(
    ReferralActionsTypes.RedeemGiveawayItem,
    props<{ payload: { giveawayId: string; itemId: string } }>()
);
export const redeemGiveawaySuccess = createAction(
    ReferralActionsTypes.RedeemGiveawaySuccess,
    props<{ payload: Redemption }>()
);
export const redeemGiveawayFailure = createAction(
    ReferralActionsTypes.RedeemGiveawayFailure,
    props<{ payload: APIError }>()
);

export const loadGiveawayRedemptions = createAction(ReferralActionsTypes.LoadGiveawayRedemptions);
export const loadGiveawayRedemptionsSuccess = createAction(
    ReferralActionsTypes.LoadGiveawayRedemptionsSuccess,
    props<{ payload: Redemption[] }>()
);
export const loadGiveawayRedemptionsFailure = createAction(
    ReferralActionsTypes.LoadGiveawayRedemptionsFailure,
    props<{ payload: APIError }>()
);

export const loadActiveAdCampaignsAdvertisements = createAction(
    ReferralActionsTypes.LoadActiveAdCampaignsAdvertisements
);
export const loadActiveAdCampaignsAdvertisementsSuccess = createAction(
    ReferralActionsTypes.LoadActiveAdCampaignsAdvertisementsSuccess,
    props<{ payload: Advertisement[] }>()
);
export const loadActiveAdCampaignsAdvertisementsFailure = createAction(
    ReferralActionsTypes.LoadActiveAdCampaignsAdvertisementsFailure,
    props<{ payload: APIError }>()
);

export const setLoadActiveAdCampaignsAdvertisementsComplete = createAction(
    ReferralActionsTypes.SetLoadActiveAdCampaignsAdvertisementsComplete,
    props<{ payload: boolean }>()
);

/**
 * Ad tracking actions.
 */

export const trackAdImpressionEvent = createAction(
    ReferralActionsTypes.TrackAdImpressionEvent,
    props<{
        payload: {
            advertisementId: string;
            url: string;
            title: string;
            item?: string;
            itemType?: string;
            metadata?: any;
        };
    }>()
);
export const trackAdImpressionEventSuccess = createAction(
    ReferralActionsTypes.TrackAdImpressionEventSuccess,
    props<{ payload: AdEvent }>()
);
export const trackAdImpressionEventFailure = createAction(
    ReferralActionsTypes.TrackAdImpressionEventFailure,
    props<{ payload: APIError }>()
);

export const trackAdClickEvent = createAction(
    ReferralActionsTypes.TrackAdClickEvent,
    props<{
        payload: {
            advertisementId: string;
            url: string;
            title: string;
            item?: string;
            itemType?: string;
            metadata?: any;
        };
    }>()
);
export const trackAdClickEventSuccess = createAction(
    ReferralActionsTypes.TrackAdClickEventSuccess,
    props<{ payload: AdEvent }>()
);
export const trackAdClickEventFailure = createAction(
    ReferralActionsTypes.TrackAdClickEventFailure,
    props<{ payload: APIError }>()
);

