import { AdCampaign } from './ad-campaign.model';
import { Advertiser } from './advertiser.model';

export enum AdvertisementPlacement {
    ARTICLE_HEADER = 'article_header',
    ARTICLE_PREINTRO = 'article_pre-intro',
    ARTICLE_INLINE = 'article_inline',
    ARTICLE_INLINE_FIRST = 'article_inline_first',
    ARTICLE_INLINE_REPEAT = 'article_inline_repeat',
    ARTICLE_VERTICAL_STICKY = 'article_vertical_sticky',
    ARTICLE_VERTICAL_STICKY_FIRST = 'article_vertical_sticky_first',
    ARTICLE_VERTICAL_STICKY_REPEAT = 'article_vertical_sticky_repeat'
}

export enum AdvertisementType {
    DESKTOP = 'desktop',
    MOBILE = 'mobile'
}

export enum AdvertisementFormat {
    IMAGE = 'image',
    VIDEO = 'video'
}

export enum AdvertisementFilterType {
    GAME = 'game',
    CATEGORY = 'category'
}

export const AdvertisementPlacementSize = {
    [AdvertisementPlacement.ARTICLE_HEADER]: {
        [AdvertisementType.DESKTOP]: {
            width: 970,
            height: 250,
            aspectRatio: '3.88/1'
        },
        [AdvertisementType.MOBILE]: {
            width: 320,
            height: 100,
            aspectRatio: '3.2/1'
        }
    },
    [AdvertisementPlacement.ARTICLE_PREINTRO]: {
        [AdvertisementType.DESKTOP]: {
            width: 728,
            height: 90,
            aspectRatio: '8.09/1'
        },
        [AdvertisementType.MOBILE]: {
            width: 300,
            height: 250,
            aspectRatio: '6/5'
        }
    },
    [AdvertisementPlacement.ARTICLE_INLINE_FIRST]: {
        [AdvertisementType.DESKTOP]: {
            width: 728,
            height: 280,
            aspectRatio: '2.6/1'
        },
        [AdvertisementType.MOBILE]: {
            width: 300,
            height: 600,
            aspectRatio: '1/2'
        }
    },
    [AdvertisementPlacement.ARTICLE_INLINE_REPEAT]: {
        [AdvertisementType.DESKTOP]: {
            width: 728,
            height: 280,
            aspectRatio: '2.6/1'
        },
        [AdvertisementType.MOBILE]: {
            width: 300,
            height: 600,
            aspectRatio: '1/2'
        }
    },
    [AdvertisementPlacement.ARTICLE_VERTICAL_STICKY_FIRST]: {
        [AdvertisementType.DESKTOP]: {
            width: 300,
            height: 600,
            aspectRatio: '1/2'
        }
    },
    [AdvertisementPlacement.ARTICLE_VERTICAL_STICKY_REPEAT]: {
        [AdvertisementType.DESKTOP]: {
            width: 300,
            height: 600,
            aspectRatio: '1/2'
        }
    }
};

export const AdvertisementPlacementPlatforms = Object.keys(AdvertisementPlacementSize).reduce(
    (acc, placement) => {
        acc[placement] = {
            mobile: !!AdvertisementPlacementSize[placement][AdvertisementType.MOBILE],
            desktop: !!AdvertisementPlacementSize[placement][AdvertisementType.DESKTOP]
        };
        return acc;
    },
    {}
);

export const AdvertisementPlacementFilterType = {
    [AdvertisementPlacement.ARTICLE_HEADER]: AdvertisementFilterType.CATEGORY,
    [AdvertisementPlacement.ARTICLE_PREINTRO]: AdvertisementFilterType.CATEGORY,
    [AdvertisementPlacement.ARTICLE_INLINE_FIRST]: AdvertisementFilterType.CATEGORY,
    [AdvertisementPlacement.ARTICLE_INLINE_REPEAT]: AdvertisementFilterType.CATEGORY,
    [AdvertisementPlacement.ARTICLE_VERTICAL_STICKY_FIRST]: AdvertisementFilterType.CATEGORY,
    [AdvertisementPlacement.ARTICLE_VERTICAL_STICKY_REPEAT]: AdvertisementFilterType.CATEGORY
};

// export the advertisement placement names as an array
export const AdvertisementPlacementNames = {
    [AdvertisementPlacement.ARTICLE_HEADER]: 'Article Header',
    [AdvertisementPlacement.ARTICLE_PREINTRO]: 'Article Pre-Intro',
    [AdvertisementPlacement.ARTICLE_INLINE_FIRST]: 'Article Inline First',
    [AdvertisementPlacement.ARTICLE_INLINE_REPEAT]: 'Article Inline Repeat',
    [AdvertisementPlacement.ARTICLE_VERTICAL_STICKY_FIRST]: 'Article Sticky First',
    [AdvertisementPlacement.ARTICLE_VERTICAL_STICKY_REPEAT]: 'Article Sticky Repeat'
};

export const AdvertisementPlacementClasses = {
    [AdvertisementPlacement.ARTICLE_HEADER]: ['header'],
    [AdvertisementPlacement.ARTICLE_PREINTRO]: ['pre-intro'],
    [AdvertisementPlacement.ARTICLE_INLINE_FIRST]: ['in-content'],
    [AdvertisementPlacement.ARTICLE_INLINE_REPEAT]: ['in-content'],
    [AdvertisementPlacement.ARTICLE_VERTICAL_STICKY_FIRST]: ['vertical-sticky'],
    [AdvertisementPlacement.ARTICLE_VERTICAL_STICKY_REPEAT]: ['vertical-sticky']
};

export interface AdvertisementItem {
    _id: string;
    type: AdvertisementType;
    format: AdvertisementFormat;
    scale: number;
    mimeType: string;
    imagePath?: string;
}
export interface Advertisement {
    _id: string;
    name: string;
    url: string;
    filter: string;
    filterType: string;
    filterName: string;
    adCampaign: AdCampaign;
    advertiser: Advertiser;
    placement: AdvertisementPlacement;
    items: AdvertisementItem[];
    isActive: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export interface AdvertisementItemViewModel extends AdvertisementItem {
    imagePath: string;
}

export interface AdvertisementViewModel extends Advertisement {
    desktopItem: AdvertisementItemViewModel;
    mobileItem: AdvertisementItemViewModel;
}

