import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Game } from '../../core/constants/game.enum';
import { Redemption } from '../../models/redemption.model';
import { logoutUser, logoutUserInterceptor } from '../actions/auth.actions';
import * as persistActions from '../actions/persist.actions';
import * as referralActions from '../actions/referral.actions';
import * as searchActions from '../actions/search.actions';

const selectId = (entity: any) => entity;
export const autocompleteTermsAdapter = createEntityAdapter<string>({ selectId });
export const redemptionsEntityAdapter = createEntityAdapter<Redemption>({
    selectId: obj => obj._id
});
export interface PersistState {
    currentGame: Game | null | undefined;
    authToken: string | null | undefined;
    refreshToken: string | null;
    cookiesBannerDismissed: boolean;
    dismissedAnnouncements: string[];
    dismissedGiveaways: string[];
    pushNotificationsBannerDismissed: boolean;
    promoBannerExpanded: boolean;
    playerSettings: {
        speed: number;
        quality: number;
    };
    autocompleteTerms: EntityState<string>;
    giveawayRedemptions: EntityState<Redemption>;
}

export const persistInitialState: PersistState = {
    currentGame: null,
    authToken: null,
    refreshToken: null,
    cookiesBannerDismissed: false,
    dismissedAnnouncements: [],
    dismissedGiveaways: [],
    pushNotificationsBannerDismissed: false,
    promoBannerExpanded: true,
    playerSettings: {
        speed: 1,
        quality: 0
    },
    autocompleteTerms: autocompleteTermsAdapter.getInitialState(),
    giveawayRedemptions: redemptionsEntityAdapter.getInitialState()
};

export const persistReducer = createReducer(
    persistInitialState,
    on(persistActions.setCurrentGame, (state, { payload }) => ({
        ...state,
        currentGame: payload
    })),
    on(persistActions.setAuthToken, (state, { payload }) => ({
        ...state,
        authToken: payload
    })),
    on(persistActions.setRefreshToken, (state, { payload }) => ({
        ...state,
        refreshToken: payload
    })),
    on(persistActions.dismissCookiesBanner, state => ({
        ...state,
        cookiesBannerDismissed: true
    })),
    on(persistActions.dismissAnnouncementOverlay, (state, { payload }) => ({
        ...state,
        dismissedAnnouncements: [...new Set([...state.dismissedAnnouncements, payload])]
    })),
    on(persistActions.dismissGiveawayOverlay, (state, { payload }) => ({
        ...state,
        dismissedGiveaways: [...new Set([...state.dismissedGiveaways, payload])]
    })),
    on(persistActions.dismissPushNotificationsBanner, state => ({
        ...state,
        pushNotificationsBannerDismissed: true
    })),
    on(persistActions.dismissPushNotificationsBanner, state => ({
        ...state,
        pushNotificationsBannerDismissed: true
    })),
    on(persistActions.collapsePromoBanner, state => ({
        ...state,
        promoBannerExpanded: false
    })),
    on(persistActions.expandPromoBanner, state => ({
        ...state,
        promoBannerExpanded: true
    })),
    on(persistActions.setPlayerSettings, (state, { payload }) => ({
        ...state,
        playerSettings: {
            ...state.playerSettings,
            ...payload
        }
    })),
    on(searchActions.autocompleteTermAdd, (state, { payload }) => ({
        ...state,
        autocompleteTerms: autocompleteTermsAdapter.addOne(payload.term, state.autocompleteTerms)
    })),
    on(searchActions.autocompleteTermRemove, (state, { payload }) => ({
        ...state,
        autocompleteTerms: autocompleteTermsAdapter.removeOne(payload.term, state.autocompleteTerms)
    })),
    on(referralActions.loadGiveawayRedemptionsSuccess, (state, { payload }) => ({
        ...state,
        giveawayRedemptions: redemptionsEntityAdapter.setAll(payload, state.giveawayRedemptions)
    })),
    on(referralActions.redeemGiveawaySuccess, (state, { payload }) => ({
        ...state,
        giveawayRedemptions: redemptionsEntityAdapter.upsertOne(payload, state.giveawayRedemptions)
    })),
    on(logoutUser, logoutUserInterceptor, state => ({
        ...state,
        giveawayRedemptions: redemptionsEntityAdapter.getInitialState()
    }))
);

