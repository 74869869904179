import { Params } from '@angular/router';
import { getRouterSelectors } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouteSelectorMap, RouteSelectorProps } from '../../models/route-selectors.model';
import { GlobalState } from '../store';

const selectRouter = createFeatureSelector<GlobalState, any>('router');

export const {
    selectCurrentRoute, // select the current route
    selectFragment, // select the current route fragment
    selectQueryParams, // select the current route query params
    selectQueryParam, // factory function to select a query param
    selectRouteParams, // select the current route params
    selectRouteParam, // factory function to select a route param
    selectRouteData, // select the current route data
    selectUrl // select the current url
} = getRouterSelectors(selectRouter);

export const selectCustomRouteParamFragment = createSelector(
    selectRouteParams,
    (routeParams: Params, props: { param: string }) => {
        return routeParams && props.param && routeParams[props.param]
            ? routeParams[props.param].split('~')[1]
            : null;
    }
);
export const selectCustomRouteParamsFragment = createSelector(
    selectRouteParams,
    (routeParams: Params, props: string[] = []) => {
        let propsValues = new Array(props.length - 1);
        propsValues.fill(null);

        props.forEach((prop: string, index: number) => {
            if (routeParams && routeParams[prop]) {
                propsValues[index] = routeParams[prop].split('~')[1];
            }
        });

        return propsValues;
    }
);

export const selectCustomRouteFragment = (props: RouteSelectorProps) =>
    createSelector(
        selectRouteParams,
        selectQueryParams,
        (params: any, queryParams: any): RouteSelectorMap => {
            const resultMap = { paramMap: {}, queryMap: {} };

            props.paramMap?.forEach((prop: string, index: number) => {
                if (params && params[prop]) {
                    resultMap.paramMap[prop] = params[prop];
                }
            });
            props.queryMap?.forEach((prop: string) => {
                if (queryParams && queryParams[prop]) {
                    resultMap.queryMap[prop] = queryParams[prop];
                }
            });

            return resultMap;
        }
    );

