import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { GiveawayType } from '../../models/giveaway.model';
import { Redemption, RedemptionItem } from '../../models/redemption.model';
import {
    autocompleteTermsAdapter,
    PersistState,
    redemptionsEntityAdapter
} from '../reducers/persist.reducer';
import { GlobalState } from '../store';

const selectPersist = createFeatureSelector<MemoizedSelector<GlobalState, any>>(
    'persist'
).projector((state: GlobalState) => state.persist);

const autocompleteTermsSelectors = autocompleteTermsAdapter.getSelectors();
const giveawayRedemptionsSelectors = redemptionsEntityAdapter.getSelectors();

export const selectAutocompleteTermsSubstate = createSelector(
    selectPersist,
    (state: PersistState) => state.autocompleteTerms
);
export const selectGiveawayRedemptionsSubstate = createSelector(
    selectPersist,
    (state: PersistState) => state.giveawayRedemptions
);
export const selectAuthToken = createSelector(selectPersist, (state: PersistState) => {
    if (typeof window !== 'undefined' && localStorage) {
        const lsToken = localStorage.getItem('authToken');
        if (lsToken) {
            return lsToken;
        }
    }

    return state.authToken;
});
export const selectRefreshToken = createSelector(selectPersist, (state: PersistState) => {
    return state.refreshToken;
});
export const selectCurrentGame = createSelector(
    selectPersist,
    (state: PersistState) => state.currentGame
);
export const selectCookiesBannerDismissed = createSelector(
    selectPersist,
    (state: PersistState) => state.cookiesBannerDismissed
);
export const selectPushNotificationsBannerDismissed = createSelector(
    selectPersist,
    (state: PersistState) => state.pushNotificationsBannerDismissed
);
export const selectDismissedAnnouncements = createSelector(
    selectPersist,
    (state: PersistState) => state.dismissedAnnouncements
);
export const selectDismissedGiveaways = createSelector(
    selectPersist,
    (state: PersistState) => state.dismissedGiveaways
);
export const selectPromoBannerState = createSelector(
    selectPersist,
    (state: PersistState) => state.promoBannerExpanded
);
export const selectPlayerSettings = createSelector(
    selectPersist,
    (state: PersistState) => state.playerSettings
);
export const selectAutocompleteTerms = createSelector(
    selectAutocompleteTermsSubstate,
    autocompleteTermsSelectors.selectAll
);
export const selectAllGiveawayRedemptions = createSelector(
    selectGiveawayRedemptionsSubstate,
    giveawayRedemptionsSelectors.selectAll
);
export const selectIncompleteRedemptions = createSelector(
    selectAllGiveawayRedemptions,
    (redemptions: Redemption[]) =>
        redemptions.filter(red => red.items.length < (red.giveaway?.redeemableItemsLimit as number))
);
export const selectIsItemRedeemable = (itemId: string, type: GiveawayType) =>
    createSelector(
        selectAllGiveawayRedemptions,
        selectIsItemRedeemed(itemId),
        (redemptions: Redemption[], itemAlreadyRedeemed: boolean) =>
            !itemAlreadyRedeemed &&
            redemptions.some(
                red =>
                    red.giveaway.type === type &&
                    red.items.length < (red.giveaway?.redeemableItemsLimit as number)
            )
    );
export const selectIsItemRedeemed = (itemId: string) =>
    createSelector(selectAllGiveawayRedemptions, (redemptions: Redemption[]) =>
        redemptions.some(red => red.items.some((item: RedemptionItem) => item.itemId == itemId))
    );
